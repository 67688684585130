import { render, staticRenderFns } from "./prodTag.vue?vue&type=template&id=613322fb&scoped=true&"
import script from "./prodTag.vue?vue&type=script&lang=js&"
export * from "./prodTag.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "613322fb",
  null
  
)

export default component.exports